<template>
    <div class="container-fluid">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <h1>{{ $t('general.empresa') }}</h1>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="row">
                <div class="col-md-2">
                    <div class="card bg-gradient-primary">
                        <div class="card-body box-profile">
                            <h3 class="profile-username text-center">{{ empresa.nombre_empresa }}</h3>
                        </div>
                    </div>
                    <div class="callout callout-info">
                        <h5>{{ $t('general.ultimamodificacion') }}</h5>
                        <p>{{ empresa.fecha_actualiza_configuracion }}</p>
                    </div>
                </div>
               <!-- <div class="col-md-10">
                    <div  class="card card-default card-tabs">
                        <div class="card-header p-1 pt-1">
                            <ul class="nav nav-pills">
                                <li class="nav-item"><a class="nav-link active" href="#datosempresa_datos" data-toggle="tab">{{
                                    $t('general.datos') }}</a></li>
                                <li class="nav-item lazy"><a class="nav-link" href="#datosempresa_ubicaciones" data-toggle="tab">{{ $t('general.ubicaciones') }}</a></li>
                            </ul>
                        </div>
                    </div>
                    <br>
                    <div class="tab-content">
                        <div class="active tab-pane" id="datosempresa_datos">
                            <datosempresa-datos />
                        </div>
                        <div class="tab-pane"  id="datosempresa_ubicaciones">
                            <datosempresa-correo />
                        </div>
                    </div>
                </div>-->
              <div class="col-md-10"  >
                    <TabView @tab-change="cambiopestania" :activeIndex="activo" >
                        <TabPanel header="Datos">
                            <datosempresa-datos :empresa="empresa" v-once />
                        </TabPanel>
                        <TabPanel header="Ubicaciones" >
                            <datosempresa-correo v-if="indice === 1" />
                        </TabPanel>
                    </TabView>
                </div> 
            </div>
        </section>
    </div>
</template>
<script>
import { PwgsApi } from '../../../services/PwgsApi';
import DatosEmpresaDatos from './datos/DatosEmpresaDatos.vue';
import DatosEmpresaCorreo from './ubicaciones/DatosEmpresaUbicaciones.vue';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
export default {
    components: {
                'datosempresa-datos': DatosEmpresaDatos,
                'datosempresa-correo': DatosEmpresaCorreo,
                'TabView': TabView,
                'TabPanel': TabPanel,               
                },
    data() {
        return {
            empresa: [],
            ubicaciones: '',
            cabeceradatos: '',
            show: false,
            indice: 0,
            activo:0,
        }
    },
    methods: {
       
        async obtenerEmpresa() {
            const api = new PwgsApi;
            const datos = await api.get('empresa');
            this.empresa = datos.datos;
        },  
        cambiopestania(evento) { 
            this.indice = evento.index;
        },
        cambioactivo() {
            this.activo = 0;
            this.activo = 1;
         }     
    },
    mounted() {
        this.obtenerEmpresa();
        this.ubicaciones = this.$t("general.ubicaciones"); 
        this.cabeceradatos = this.$t('general.datos');
    },
    watch: {
        id() {
           this.obtenerEmpresa();
        }
    }
}
</script>
<style>
 .p-tabview,.p-tabview-panels
{
    background: #f4f6f9 !important;
    border:1px !important;
    border-width: 1px 1px 1px 1px  !important;
    font-family: "Source Sans Pro" !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus
{
    background-color: #007bff !important;
    color: #ffffff !important;
    border-radius: 4px;
    box-shadow: none !important;
}
.p-tabview-nav-container,.p-tabview-nav-link
{
    background: #ffffff !important;
    border:1px !important;
    border-width: 1px 1px 1px 1px  !important;
}
.p-tabview-nav-container
{
    box-shadow: 0 0 1px rgba(0,0,0,.125),0 1px 3px rgba(0,0,0,.2) !important;
    border-radius: 0.25rem;
    margin-bottom: 1rem !important;
    border:0 solid rgba(0,0,0,.125) !important;
    background-clip: border-box !important;
}
 .p-tabview-nav-link
{
    margin: 0 1px 1px !important;
}
.p-tabview-nav li
{
    margin-left: 0.25rem !important;
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
}
.p-tabview-title
{
    font-weight: 400 !important;  
}
.p-highlight > .p-tabview-nav-link
{
    background-color: #007bff !important;
    color: #ffffff !important; 
    border-radius: 4px;
}
.p-tabview-nav-link:hover
{
    color:#007bff !important;
}
.p-highlight > .p-tabview-nav-link:hover
{
        color:#ffffff !important;
}
.p-tabview-panels
{
    padding-left: 0 !important;
}
</style>